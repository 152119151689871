// Spinner.js
import React from 'react';
import gif from '../assets/Diamond_City.gif'

const Spinner = () => {
    return (
        <div className={`fixed inset-0 bg-onyx lg:h-[100vh] flex justify-center items-center flex-col z-[100]`}>
            <img src={gif} alt="Loading..." className="mb-6 mt-14 scale-125 lg:scale-100" />
        </div>
    );
};

export default Spinner;
